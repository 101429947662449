<template>
  <v-footer
    class="container"
    color="transparent"
    height="auto"
    min-height="70px"
    padless
  >
    <v-row justify="center">
      <v-col class="text-center" cols="12" py-3 style="padding-bottom: 24px">
        <span class="body-2 hcnm-footer">
          <a
            href="https://play.google.com/store/apps/details?id=com.hcnm.phrasebook"
            class="noUnderline"
          >
            <v-icon>fab fa-google-play</v-icon>
            {{ $t("frontEnd.downloadAndroid") }}
          </a>
          |
          <a
            href="https://apps.apple.com/app/kitap-kitapi/id1489302662"
            class="noUnderline"
          >
            <v-icon>fab fa-app-store-ios</v-icon>
            {{ $t("frontEnd.downloadIos") }}
          </a>
          |
          <a href="/api/download/phrasebook" class="noUnderline" download
            ><v-icon>fas fa-file-pdf</v-icon></a
          >
          <a href="/api/download/phrasebook" download>{{
            $t("frontEnd.downloadPdf")
          }}</a>
        </span>
      </v-col>
      <v-col
        class="text-center"
        cols="12"
        py-3
        style="padding-top: 24px; padding-bottom: 24px"
      >
        <span style="color: #404040; font-weight: bold" class="body-2 mr-2">
          The views expressed in this publication are those of the authors and
          do not necessarily represent those of the OSCE HCNM or AHDR.
        </span>
      </v-col>
      <v-col class="text-center" cols="12" py-3 style="padding-bottom: 10px">
        <span style="color: #404040" class="body-2 mr-2"
          >{{ $t("frontEnd.copyright") }} &copy;{{
            new Date().getFullYear()
          }}</span
        ><span class="body-2 hcnm-footer"
          >|
          <a href="https://www.osce.org/hcnm" target="_blank"
            >OSCE High Commissioner on National Minorities</a
          >
        </span>
      </v-col>
      <v-col class="text-center" cols="12" py-3 style="padding-top: 0">
        <span class="body-2 hcnm-footer">
          <a href="http://gra-des.com/" target="_blank"
            >{{ $t("frontEnd.designedBy1") }} Elena Nicolaou
            {{ $t("frontEnd.designedBy2") }}</a
          >
          |
          <a href="https://jensen.technology" target="_blank"
            >{{ $t("frontEnd.developmentBy1") }} Jensen Technologies
            {{ $t("frontEnd.developmentBy2") }}</a
          >
          |
          <a href="https://www.ahdr.info/" target="_blank">{{
            $t("frontEnd.contentDevelopedBy")
          }}</a>
        </span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.hcnm-footer a {
  text-decoration: none;
  color: #404040;
}
.hcnm-footer a:hover {
  text-decoration: underline;
}
</style>
