/*
|-------------------------------------------------------------------------------
| VUEX modules/users.js
|-------------------------------------------------------------------------------
| The Vuex data store for the users
*/

import ApiUser from "../api/user.js";

/*
|-------------------------------------------------------------------------------
| DIFFERENTS STATES FOR LOADING
|-------------------------------------------------------------------------------
| status = 0 -> No loading has begun
| status = 1 -> Loading has started
| status = 2 -> Loading completed successfully
| status = 3 -> Loading completed unsuccessfully
|-------------------------------------------------------------------------------
*/

export const users = {
    state: {
        user: {},
        token: localStorage.getItem("token") || "",
        LOGGED_IN: false,
        usersList: [],
        registerFormValidator: [],

    },
    actions: {
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ApiUser.login(payload).then(
                    response => {
                        if (response.data.token) {
                            const token = response.data.token;
                            localStorage.setItem("token", response.data.token);
                            axios.defaults.headers.common[
                                "Authorization"
                            ] = token;
                            commit("setToken", token);
                            commit("setUser", response.data);
                            commit("setLoggedIn", true);
                            resolve({failed: false});
                        } else if ( response.data.code === 401) {
                            resolve({failed: true, msg: "Unauthorized"})
                        }
                    },
                    error => {
                        reject(false);
                    }
                );
            });
        },

        checkAuth({ commit, state }) {
            const token = state.token || localStorage.getItem("token");

            return new Promise((resolve, reject) => {
                ApiUser.checkAuth({ token }).then(
                    response => {
                        if (response.status === 200) {
                            commit("setUser", response.data.user);
                            commit("setLoggedIn", true);
                            commit("setToken", response.data.token);
                        }
                        resolve(true);
                    },
                    error => {
                        commit("setUser", {});
                        commit("setLoggedIn", false);
                        commit("setToken", "");
                        reject(error);
                    }
                );
            });
        },

        logout({ commit }) {
            return new Promise((resolve, reject) => {
                ApiUser.logout().then(
                    response => {
                        commit("setToken", "");
                        commit("setLoggedIn", false);
                        commit("setUser", {});
                        resolve();
                        localStorage.removeItem("token");
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        loadAllUsers({ commit }) {
            return new Promise((resolve, reject) => {
                ApiUser.loadAllUsers().then(
                    response => {
                        commit("setAllUsers", response.data.users);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        newUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ApiUser.newUser(payload)
                    .then(response => {
                        commit("addNewUserToList", response.data.user);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        },

        loadUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ApiUser.loadUser(payload).then(
                    response => {
                        resolve(response.data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },

        updateUser({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ApiUser.updateUser(payload)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response.data);
                    });
            });
        },

        sendEmail({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiUser.sendEmail(payload)
                    .then( response => {
                        console.log(response);
                        resolve();
                    })
            }))
        }
    },

    mutations: {
        setUser: (state, payload) => (state.user = payload),

        setLoggedIn: (state, payload) => (state.LOGGED_IN = payload),

        setToken: (state, payload) => (state.token = payload),

        setAllUsers: (state, payload) => (state.usersList = payload),

        setRegisterFormValidator: (state, payload) =>
            (state.registerFormValidator = payload),

        addNewUserToList: (state, payload) => state.usersList.push(payload)
    },

    getters: {
        getUser: state => state.user,

        getLoggedIn: state => state.LOGGED_IN,

        allUsers: state => state.usersList,

        registerFormValidator: state => state.registerFormValidator
    }
};
