/*
|-------------------------------------------------------------------------------
| VUEX modules/phrases.js
|-------------------------------------------------------------------------------
| The Vuex data store for the phrases
*/

import ApiAudiotrack from "../api/audiotrack";

/*
|-------------------------------------------------------------------------------
| DIFFERENTS STATES FOR LOADING
|-------------------------------------------------------------------------------
| status = 0 -> No loading has begun
| status = 1 -> Loading has started
| status = 2 -> Loading completed successfully
| status = 3 -> Loading completed unsuccessfully
|-------------------------------------------------------------------------------
*/

export const audiotracks = {
    state: {
        audiotracks: []
    },
    actions: {
        /*
            Called by administrator, required JWT Authorization
         */

        storeAudiotrack({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ApiAudiotrack.store(payload).then(
                    response => {
                        resolve(response.data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        deleteAudiotracks({ commit }, payload) {
            return new Promise((resolve, reject) => {
                ApiAudiotrack.removeAudiotracks(payload).then(
                    response => {
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },

        syncAudios({ commit }) {
            return new Promise((resolve, reject) => {
                ApiAudiotrack.syncAudios().then(
                    response => {
                        resolve(response.data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        }
    },

    mutations: {
        setAudiotracks: (state, payload) => (state.audiotracks = payload)
    },

    getters: {
        getAudiotracks: state => state.audiotracks
    }
};
