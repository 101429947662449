/*
|-------------------------------------------------------------------------------
| VUEX modules/phrases.js
|-------------------------------------------------------------------------------
| The Vuex data store for the phrases
*/

import ApiExercises from "../api/exercise";
import lodash from "lodash";
/*
|-------------------------------------------------------------------------------
| DIFFERENTS STATES FOR LOADING
|-------------------------------------------------------------------------------
| status = 0 -> No loading has begun
| status = 1 -> Loading has started
| status = 2 -> Loading completed successfully
| status = 3 -> Loading completed unsuccessfully
|-------------------------------------------------------------------------------
*/

export const exercises = {
    state: {
        headers: [
            {text: "Sort", value: "order", sortable: false},
            {text: "Published", value: "published", sortable: false},
            {text: "Id", value: "id", sortable: false},
            {text: "English text", value: "text_english", sortable: false},
            {text: "Greek text", value: "text_greek", sortable: false},
            {text: "Turkish text", value: "text_turkish", sortable: false},
            {text: "Actions", value: "", sortable: false}
        ],
        exercises: [],
        exerciseTypes: [

            {
                title: "Select the word that best describes the image",
                icon: "fas fa-image",
                key: "listening",
                color: "orange lighten-1",
                type: "4"
            },
            {
                title: "Fill in the blanks",
                icon: "fas fa-align-left",
                key: "vocabulary",
                color: "green lighten-1",
                type: "1"
            },
            {
                title: "Choose the right translation",
                icon: "fas fa-assistive-listening-systems",
                key: "sentence",
                color: "light-blue lighten-1",
                type: "2"
            },
            {
                title: "Translate the word",
                icon: "fas fa-i-cursor",
                key: "translation",
                color: "yellow darken-1",
                type: "3"
            },
        ],
        exercise: {},
        updatingOrderState: false,
        localStorageExercisesData: [],
        localStorageGlobalData: [],
        answerInput: "",
        exerciseIds: null,
        percentageThemes: {},
        percentageTypes: {},
        percentageLanguage: 0
    },
    actions: {
        updateOrder({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                let changes = [];
                // I will create an array of objects with the id and the new order of the exercises and update the order
                // of all of them.
                payload.forEach((exercise, index) => {
                    changes.push({id: exercise.id, order: index + 1});
                });
                // First update ui to let user see the changes.
                commit("UPDATE_ORDER", [payload, changes]);
                // Update database
                ApiExercises.updateOrder(changes).then(() => {
                    // is important to resolve to let to know the frontend what is finished
                    resolve();
                });
            });
        },
        getExercises({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                if (
                    Object.values(payload).includes("") ||
                    Object.values(payload).length !== 2
                ) {
                    reject({error: "Exercise type or theme is incorrect"});
                }
                ApiExercises.getExercises(payload).then(response => {
                    commit("SET_EXERCISES", response.data);
                    resolve(response.data);
                });
            });
        },
        createExercise({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.createExercises(payload)
                    .then(response => {
                        resolve(response.data.exercise);
                    })
                    .catch(err => {
                        console.error(err.response.data.errors);
                        reject(err.response.data.errors);
                    });
            });
        },
        getExercise({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.getExercise(payload)
                    .then(response => {
                        commit("SET_EXERCISE", response.data);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        updateExercise({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.updateExercise(payload)
                    .then(response => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        deleteExercise({commit, state}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.deleteExercise(payload)
                    .then(res => {
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        addWrongAnswers({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.addWrongAnswers(payload).then(res => {
                });
            });
        },
        storeAudio({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiExercises.storeAudio(payload)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })
            }))
        },
        deleteAudio({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.deleteAudio(payload).then(res => {
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        storeWrongAnswers({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiExercises.storeWrongAnswer(payload)
                    .then(res => {
                        resolve(res.data)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })

            }))
        },
        updateWrongAnswers({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiExercises.updateWrongAnswers(payload).then(res => {
                }).catch(err => {
                })
            }))
        },
        deleteWrongAnswer({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiExercises.deleteWrongAnswer(payload).then(res => {
                    resolve()
                }).catch(err => {
                })
            }))
        },
        insertWrongAnswer({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiExercises.insertWrongAnswer(payload).then(
                    res => {
                        resolve()
                    }
                ).catch(err => {
                })
            }))
        },
        uploadImage({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiExercises.uploadImage(payload)
                    .then(res => {
                        if (res.data) {
                            resolve(res.data);
                        }
                    })
                    .catch(err => {
                        reject(err.response.data.errors)
                    })

            })
        },
        deleteImage({commit}, payload) {
            return new Promise(((resolve, reject) => {
                ApiExercises.deleteImage(payload).then(res => {
                    resolve()
                }).catch(err => {
                })
            }))
        },
        getExerciseList({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                dispatch('getExercisesLocalStorage', payload);
                ApiExercises.getExerciseList(payload).then(res => {
                    commit('SET_EXERCISES', res.data)
                    resolve()
                })
            })
        },
        getExerciseIdsList({commit, dispatch}) {
            return new Promise((resolve, reject) => {
                ApiExercises.getExerciseIdsList().then(res => {
                    commit('SET_EXERCISE_IDS', res.data)
                    resolve(res.data)
                })
            })
        },
        getExercisesLocalStorage({commit, state}, data = null) {
            return new Promise((resolve => {
                const ls = getLocalStorageGlobal(data.lang)
                commit('SET_LOCALSTORAGE_GLOBAL_DATA', ls)
                _.unset(data, 'lang')
                if (!_.isEmpty(data)) {
                    commit('SET_LOCALSTORAGE_DATA', getLocalStorageExercises(state.localStorageGlobalData, data))
                }
                resolve(ls);
            }))

        },
        addExerciseLocalStorage({commit, state, dispatch}, payload) {
            // Obtain current localstorage
            commit('SET_LOCALSTORAGE_GLOBAL_DATA', getLocalStorageGlobal(payload.lang))
            // Add the new exercise and return new localstorage object
            let newLocalStorage = addLocalStorageExercises(state.localStorageGlobalData, payload)
            // Update localstorage with the new object
            setLocalStorageGlobal(payload.lang, newLocalStorage)
            // Update vuex with new global localstorage
            commit('SET_LOCALSTORAGE_GLOBAL_DATA', newLocalStorage)
            // Update vuex with the array of exercises finalized
            commit('SET_LOCALSTORAGE_DATA', getLocalStorageExercises(state.localStorageGlobalData, payload))
            dispatch('getPercentageThemes', payload.lang)
        },
        getExercisesIdsLocalStorage({commit}) {
            return new Promise(() => {
                ApiExercises.getExerciseIdsList().then(res => {
                    commit('SET_EXERCISE_IDS', res.data);
                })
            })
        },
        getPercentageThemes({dispatch, commit}, language) {
            return new Promise((resolve => {
                let promiseList = [];
                promiseList.push(dispatch('getExerciseIdsList'))
                promiseList.push(dispatch('getExercisesLocalStorage', {lang: language}))

                Promise.all(promiseList).then(res => {
                    let completed = res[0];
                    let allTypes = res[1];
                    let themesPercentage = {};
                    for (const type in allTypes) {
                        for (const theme in allTypes[type]) {
                            let percen = (allTypes[type][theme].length / completed[type][theme] * 100)
                            themesPercentage[theme] = (themesPercentage[theme] || 0) + parseFloat(percen.toFixed(2))
                        }
                    }
                    let finalObjectThemesPercentage = {}
                    Object.entries(themesPercentage).map(theme => {
                        finalObjectThemesPercentage[theme[0]] = (theme[1] / 4)
                    })
                    const reducer = (accumulator, currentValue) => accumulator + currentValue;
                    let languagePercentage = 0;

                    if (!_.isEmpty(Object.values(finalObjectThemesPercentage))) {
                        languagePercentage = (Object.values(finalObjectThemesPercentage).reduce(reducer) / 13).toFixed(2)
                    }

                    commit('SET_EXERCISE_PERCENTAGE_LANGUAGE', languagePercentage)
                    commit('SET_EXERCISE_PERCENTAGE_THEMES', finalObjectThemesPercentage)
                    resolve(finalObjectThemesPercentage)
                })
            }))
        },

        getPercentagesTypes({commit, state, dispatch}, data) {
            return new Promise((resolve) => {
                let promiseList = [];
                promiseList.push(dispatch('getExerciseIdsList'))
                promiseList.push(dispatch('getExercisesLocalStorage', {lang: data.lang}))
                Promise.all(promiseList).then((res) => {
                    let completed = res[0];
                    let allTypes = res[1];
                    let typePercentage = {};
                    let themesPercentage = {};

                    for (const type in allTypes) {
                        for (const theme in allTypes[type]) {
                            let percenAlt = (allTypes[type][theme].length / completed[type][theme] * 100)
                            themesPercentage[theme] = parseFloat(percenAlt.toFixed(2))
                            if (data.themeId == theme) {
                                let percen = parseInt(allTypes[type][theme].length) / parseInt(completed[type][theme]) * 100
                                typePercentage[type] = parseFloat(percen.toFixed(2))

                            }
                        }
                    }
                    let finalObjectThemesPercentage = {}
                    let languagePercentage = 0;

                    Object.entries(themesPercentage).map(theme => {
                        finalObjectThemesPercentage[theme[0]] = (theme[1] / 4)
                    })

                    const reducer = (accumulator, currentValue) => accumulator + currentValue;

                    if (!_.isEmpty(Object.values(finalObjectThemesPercentage))) {
                        languagePercentage = (Object.values(finalObjectThemesPercentage).reduce(reducer) / 13).toFixed(2)
                    }

                    commit('SET_EXERCISE_PERCENTAGE_LANGUAGE', languagePercentage)
                    commit('SET_EXERCISE_PERCENTAGE_TYPES', typePercentage)
                    resolve(typePercentage)
                })

            })
        }
    },
    mutations: {
        UPDATE_ORDER: (state, newOrder) => {
            if (state.exercises.length) {
                state.exercises = newOrder[0].map(x =>
                    Object.assign(x, newOrder[1].find(y => x.id === y.id))
                );
            }
        },

        SET_EXERCISES: (state, exercises) => (state.exercises = exercises),

        SET_EXERCISE_IDS: (state, exerciseIds) => (state.exerciseIds = exerciseIds),

        SET_UPDATING_ORDER_STATUS: (state, status) => (state.updatingOrderState = status),

        SET_EXERCISE: (state, payload) => (state.exercise = payload),

        SET_NEW_EXERCISE_IMAGE: (state, url) => {
            state.exercise.source_image_path = url;
        },

        CLEAN_EXERCISES: (state, exercises) => (state.exercises = []),

        CLEAN_EXERCISE: (state, exercise) => (state.exercise = {}),

        SET_LOCALSTORAGE_DATA: (state, data) => state.localStorageExercisesData = data,

        SET_LOCALSTORAGE_GLOBAL_DATA: (state, data) => state.localStorageGlobalData = data,

        ADD_SPECIAL_CHARACTER: (state, payload) => {
            if (state.answerInput === null) {
                state.answerInput = payload;
            } else {
                state.answerInput = state.answerInput + payload;
            }
        },

        UPDATE_INPUT: (state, payload) => {
            state.answerInput = payload
        },

        SET_EXERCISE_PERCENTAGE_THEMES: (state, percentages) => state.percentageThemes = percentages,

        SET_EXERCISE_PERCENTAGE_TYPES: (state, percentages) => state.percentageTypes = percentages,

        SET_EXERCISE_PERCENTAGE_LANGUAGE: (state, percentages) => state.percentageLanguage = percentages,
    },
    getters: {
        sortedExercises: state => _.orderBy(state.exercises, ["order", "id"], ["asc", "asc"]),

        exerciseToShow(state) {
            return state.exercises.filter(e => !state.localStorageExercisesData.includes(e.id)) || false
        }
    }
};

function getLocalStorageExercises(data, payload) {
    return _.get(data, [payload.type, payload.themeId], [])
}

function addLocalStorageExercises(data, payload) {
    let exercisesFinishedInTheme = _.get(data, [payload.type, payload.themeId], [])
    if (!exercisesFinishedInTheme.includes(payload.exerciseId)) {
        exercisesFinishedInTheme = [...exercisesFinishedInTheme, payload.exerciseId]
    }
    return _.setWith(data, `[${payload['type']}][${payload['themeId']}]`, exercisesFinishedInTheme, Object)
}

function getLocalStorageGlobal(key) {
    let exercises = localStorage.getItem(`exercises-${key}`)
    return exercises ? JSON.parse(exercises) : {}
}

function setLocalStorageGlobal(key, data) {
    localStorage.setItem(`exercises-${key}`, JSON.stringify(data));

}
