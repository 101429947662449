<template>
  <v-container class="pb-0">
    <v-row class="no-print" justify="center">
      <v-col cols="12">
        <v-toolbar
          class="hidden-sm-and-down py-2"
          flat
          style="border-radius: 3px; height: auto"
        >
          <router-link :to="{ name: 'home' }" tag="div">
            <v-toolbar-title
              class="text-uppercase custom_logo"
              style="cursor: pointer"
            >
              {{ $t("frontEnd.title") }}<br />
              <div class="subtitle">{{ $t("frontEnd.subtitle") }}</div>
            </v-toolbar-title>
          </router-link>

          <v-spacer></v-spacer>

          <v-toolbar-items class="items-center">
            <v-btn text to="/welcome" class="btn-navbar">{{
              $t("frontEnd.welcome")
            }}</v-btn>
            <v-btn text to="/alphabet" class="btn-navbar">{{
              $t("frontEnd.alphabet")
            }}</v-btn>
            <v-btn text to="/contact-us" class="btn-navbar">{{
              $t("frontEnd.contact")
            }}</v-btn>
            <v-btn text to="/exercises" class="btn-navbar">{{
              $t("frontEnd.exercises")
            }}</v-btn>

            <v-btn text class="btn-navbar btn-navbar-dp">
              <v-select
                rounded
                flat
                single-line
                :items="locales"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="langSelected"
                class="language-dp"
              ></v-select>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-expansion-panels
          class="hidden-md-and-up"
          v-model="panel"
          style="box-shadow: none"
        >
          <v-expansion-panel :readonly="readonly">
            <v-expansion-panel-header style="cursor: default">
              <template v-slot:actions>
                <v-icon @click="openMenu">fas fa-grip-lines</v-icon>
              </template>
              <template @click.native.stop v-slot:default>
                <router-link
                  class="text-uppercase custom_logo"
                  tag="span"
                  to="/"
                >
                  {{ $t("frontEnd.title") }}<br />
                  <div class="subtitle">{{ $t("frontEnd.subtitle") }}</div>
                </router-link>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      class="nav-item"
                      to="/welcome"
                      v-on:click.native="closeMenu"
                      >{{ $t("frontEnd.welcome") }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      class="nav-item"
                      to="/alphabet"
                      v-on:click.native="closeMenu"
                      >{{ $t("frontEnd.alphabet") }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link
                      class="nav-item"
                      to="/contact-us"
                      v-on:click.native="closeMenu"
                      >{{ $t("frontEnd.contact") }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <router-link class="nav-item" to="/exercises">
                      {{ $t("frontEnd.exercises") }}
                    </router-link>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <a @click.prevent="changeLanguage('gr')" href="#">{{
                      $t("frontEnd.greek")
                    }}</a>
                    |
                    <a @click.prevent="changeLanguage('tr')" href="#">{{
                      $t("frontEnd.turkish")
                    }}</a>
                    |
                    <a @click.prevent="changeLanguage('en')" href="#">{{
                      $t("frontEnd.english")
                    }}</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      panel: [],
      readonly: true,
      lang: [
        { text: this.trans("frontEnd.greek"), value: "gr" },
        { text: this.trans("frontEnd.turkish"), value: "tr" },
        { text: this.trans("frontEnd.english"), value: "en" },
      ],
    };
  },
  computed: {
    locales() {
      return this.lang;
    },
    iso() {
      return this.$i18n.locale;
    },
    langSelected: {
      get() {
        return this.$i18n.locale;
      },
      set(val) {
        document.documentElement.setAttribute("lang", val);
        window.location.href = `/${val}${this.$route.fullPath}`;
      },
    },
  },
  watch: {
    langSelected(val) {
      this.$i18n.locale = val;
    },
  },
  methods: {
    trans(string) {
      return this.$t(string).toUpperCase();
    },
    openMenu() {
      this.panel = typeof this.panel === "object" ? 0 : [];
    },
    closeMenu() {
      this.panel = typeof this.panel === "object" ? 1 : [];
    },
    changeLanguage(val) {
      this.$i18n.locale = val;
    },
  },
};
</script>

<style scoped>
.v-select-list {
  padding: 0;
}

.v-list-item__content .v-list-item__title a {
  text-decoration: none;
  color: black;
  font-size: initial;
}

.v-select__selection,
.v-list-item__content {
  color: #6d6e71;
  text-transform: uppercase;
}
</style>
