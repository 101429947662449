// Import libraries and vue
import Vue from 'vue'
import VuePageTransition from 'vue-page-transition'
import SocialSharing from 'vue-social-sharing'
import VueExpandableImage from 'vue-expandable-image'

require('./plugins/toasted');
require('./plugins/axios');
require('./plugins/sweetalert')

try {
    window.Vue = require('vue');
    window.Popper = require('popper.js').default;
    window._ = require('lodash');
} catch (e) {
    console.error(`Error loading libraries. ${e}`)
}

Vue.use(require('vue-chartist'));

[VuePageTransition, SocialSharing, VueExpandableImage].forEach((x) => Vue.use(x));


