<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    let localLang = this.$i18n.locale;
    if (localLang) {
      if (localLang !== "gr" && localLang !== "tr") localLang = "en";
      this.$i18n.locale = localLang;
      document.documentElement.setAttribute("lang", localLang);
    } else {
      let navLang = navigator.language.substring(0, 2);
      if (navLang === "el") navLang = "gr";
      if (navLang !== "gr" && navLang !== "tr") navLang = "en";
      this.$i18n.locale = navLang;
      document.documentElement.setAttribute("lang", navLang);
    }
  },
};
</script>
