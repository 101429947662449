import Toasted from 'vue-toasted';
import Vue from 'vue';


const optionsSuccess = {
    theme: "bubble",
    position: "bottom-right",
    duration: 5000,
    type: 'success',
    icon: 'done',
    className: 'custom-font',

};
const optionsFailed = {
    theme: "bubble",
    position: "top-center",
    duration: 3000,
    type: 'error',
    icon: 'highlight_off',
    className: 'custom-font',

};

Vue.use(Toasted);

Vue.toasted.register('success', (payload) => {
    if (payload.message) {
        return payload.message
    }
    return "Correctly proceed."
}, optionsSuccess);

Vue.toasted.register('failed', (payload) => {
    if (payload.message) {
        return payload.message
    }
    return "Something went wrong."
}, optionsFailed);
