/*
    Imports the Phrasebook API URL from the config.
*/

export default {
    store: payload => {
        return axios.post("/admin/storeAudio", payload);
    },

    removeAudiotracks: payload => {
        return axios.post("/admin/removeAudiotracks", payload);
    },

};
