<template>
    <v-main>
        <v-row>
            <v-col>
                <p class="relatedTitle"><span class="related">{{ $t('frontEnd.relatedPhrasesTheme') }}:</span>
                    <router-link :style="{color: '#' + theme.color}" :to="{ name: 'topic', params: {id: theme.id}}">{{
                        $t(`theme.${theme.id}`) }}
                    </router-link>
                </p>
                <v-simple-table class="transpa" >
                    <thead>
                    <tr>
                        <th class="text-left table-header">{{ $t('frontEnd.cypriotTurkish') }}</th>
                        <th class="text-left table-header">{{ $t('frontEnd.standardTurkish') }}</th>
                        <th class="text-left table-header">{{ $t('frontEnd.standardGreek') }}</th>
                        <th class="text-left table-header">{{ $t('frontEnd.cypriotGreek') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <router-link style="cursor: pointer" :key="item.id" :to="{name: 'details-phrase', params: { phrase: item.id}}" tag="tr"
                                 v-for="item of internalRelated">
                        <td>{{ item.standard_greek_phrase }}</td>
                        <td>{{ item.cypriot_greek_phrase }}</td>
                        <td>{{ item.cypriot_turkish_phrase }}</td>
                        <td>{{ item.standard_turkish_phrase }}</td>
                    </router-link>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
       {{ $t(`theme.${theme.id}`) }}
    </v-main>
</template>

<script>
    export default {
        props: [
            'related',
            'theme'
        ],
        data() {
            return {}
        },
        methods: {
            demo() {
                //
            }
        },
        computed: {
            internalRelated() {
                return this.related
            }
        }
    }
</script>

<style scoped>

    .related {
        font-size:1.4rem;
        color: #555555;
    }
    .relatedTitle{
        font-size:1.4rem;
        line-height: 1.4rem;
    }

    .v-select-list.v-card.theme--light {
        padding: 0 !important;
    }
</style>
