/*
|-------------------------------------------------------------------------------
| VUEX modules/images.js
|-------------------------------------------------------------------------------
| The Vuex data store for the images
*/

import ApiImages from "../api/image.js";

/*
|-------------------------------------------------------------------------------
| DIFFERENT STATES FOR LOADING
|-------------------------------------------------------------------------------
| status = 0 -> No loading has begun
| status = 1 -> Loading has started
| status = 2 -> Loading completed successfully
| status = 3 -> Loading completed unsuccessfully
|-------------------------------------------------------------------------------
*/

export const images = {
    state: {
        imagesReadyToUpload: [],
        imagesGallery: [],
        imageSelectedGalleryModal: {},
        galleryPagination: {
            currentPage: null,
            from: null,
            to: null,
            totalItems: null,
            totalPages: null,
            perPage: 10
        }
    },
    actions: {

        // Allow store images in the server and generate multiple records in the database
        // JWT Authorization: required
        // API Endpoint: /admin/uploadImages
        uploadImages: ({commit}, payload) => {

            return new Promise((resolve, reject) => {
                ApiImages.uploadImages(payload)
                    .then(response => {
                        commit('addImagesToGallery', response.data.images);
                        commit('setImagesReadyToUpload', []);
                        resolve(response.data);
                    }, error => {
                        reject(error)
                    })
            })
        },
        // Load all the images stored in the database
        // JWT Authorization: required
        // API Endpoint: /admin/loadAllImages
        loadAllImages: ({commit}) => {

            return new Promise((resolve, reject) => {
                ApiImages.loadAllImages().then(response => {
                    commit('setImagesInGallery', response.data);
                    resolve()
                }, error => {
                    reject(error);
                })
            })
        },
        // Delete the image selected
        // JWT Authorization: required
        // API Endpoint: /admin/deleteImage/:id
        deleteImage: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                ApiImages.deleteImage(payload)
                    .then(response => {
                        commit('removeImageFromGallery', response.data.id);
                        resolve()
                    }, error => {
                        reject(error)
                    })
            })
        },
        paginateGallery({commit, state}, payload) {

            return new Promise((resolve, reject) => {
                ApiImages.paginateGallery(payload).then(response => {
                    commit('setImagesInGallery', response.data.images.data);
                    resolve(response.data.images)
                }, error => {
                    reject(error)
                })
            })
        }
    },

    mutations: {

        setImagesReadyToUpload: (state, payload) => state.imagesReadyToUpload = payload,

        addImagesToGallery: (state, payload) => state.imagesGallery = state.imagesGallery.concat(payload),

        removeImageFromGallery: (state, payload) => {
            state.imagesGallery.splice(state.imagesGallery.findIndex(img => img.id == payload), 1)
        },

        setImagesInGallery: (state, payload) => state.imagesGallery = payload,

        setImageSelectedGalleryModal: (state, payload) => state.imageSelectedGalleryModal = payload,

        setGalleryPagination: (state, payload) => {
            // state.galleryPagination.currentPage = payload.current_page;
            // state.galleryPagination.from = payload.from;
            // state.galleryPagination.to = payload.to;
            // state.galleryPagination.totalItems = payload.total;
            // state.galleryPagination.totalPages = payload.last_page;
            state.galleryPagination.perPage = payload.per_page;
        }

    },

    getters: {

        imagesReadyToUpload: state => state.imagesReadyToUpload,

        imagesGallery: state => state.imagesGallery,

        imageSelectedGalleryModal: state => state.imageSelectedGalleryModal,

        galleryPagination: state => state.galleryPagination


    }
};
