/*
    API Endpoints for Images Vuex module
*/

export default {

    uploadImages: payload => axios.post('/admin/uploadImages', payload),

    loadAllImages: () => axios.get('/admin/loadAllImages'),

    deleteImage: (payload) => axios.delete('/admin/deleteImage/' + payload),

    paginateGallery: (payload) => axios.get('/admin/galleryPagination?page=' + payload.page + '&perPage=' + payload.perPage)

}
;
