<template>
    <v-main>

        <v-row>
            <v-col class="ma-0 pa-0 d-none d-sm-flex" cols="12" style="justify-content:flex-end">
                <social-sharing :description="description"
                                :hashtags="hashtags"
                                :quote="quote"
                                :title="title"
                                :twitter-user="twitterUser"
                                :url="url"
                                inline-template
                >
                    <div class="social-sharing-media ">
                        <span class="body-1 mr-4" style="color: #2e2e2e;line-height:26px">{{ $t('frontEnd.shareOn') }}: </span>

                        <network class="mr-2" network="facebook">

                            <v-btn color="blue-grey lighten-5" depressed small>
                                <v-icon class="mr-2" color="light-blue darken-2" small>fab fa-facebook</v-icon>
                                {{ $t('frontEnd.facebook') }}
                            </v-btn>

                        </network>
                        <network class="mr-2" network="twitter">
                            <v-btn color="blue-grey lighten-5" depressed small>
                                <v-icon class="mr-2" color="light-blue lighten-3" small>fab fa-twitter</v-icon>
                                {{ $t('frontEnd.twitter') }}
                            </v-btn>
                        </network>
                        <network class="mr-2" network="telegram">
                            <v-btn color="blue-grey lighten-5" depressed small>
                                <v-icon class="mr-2" color="light-blue lighten-1" small>fab fa-telegram</v-icon>
                                {{ $t('frontEnd.telegram') }}
                            </v-btn>
                        </network>
                        <network class="mr-2" network="whatsapp">
                            <v-btn color="blue-grey lighten-5" depressed small>
                                <v-icon class="mr-2" color="green accent-3" small>fab fa-whatsapp</v-icon>
                                {{ $t('frontEnd.whatsapp') }}
                            </v-btn>
                        </network>
                        <network class="mr-2" network="linkedin">
                            <v-btn color="blue-grey lighten-5" depressed small>
                                <v-icon class="mr-2" color="blue darken-1" small>fab fa-linkedin</v-icon>
                                {{ $t('frontEnd.linkedin') }}
                            </v-btn>
                        </network>
                    </div>
                </social-sharing>
            </v-col>
            <v-col cols="12" sm="6">
                <section>
                    <div>
                        <p class="bigger">{{phrase.standard_greek_phrase}}
                            <span class="mx-3 no-print" v-if="phrase.standard_greek_audiotrack">
                                <audio :src="phrase.standard_greek_audiotrack.path" ref="sgr"></audio>
                                <v-icon @click.prevent="playAudio('sgr')"
                                        color="green darken-1" v-if="!isPlayingStandardGreek">fas fa-volume-up</v-icon>
                                <v-icon color="orange darken-4" v-else>fas fa-pause</v-icon>
                            </span>
                        </p>
                        <p class="helper">{{ $t('frontEnd.standardGreek') }}</p>
                    </div>
                    <div>
                        <p class="bigger">{{phrase.cypriot_greek_phrase}}
                            <span class="mx-3 no-print" v-if="phrase.cypriot_greek_audiotrack">
                                <audio :src="phrase.cypriot_greek_audiotrack.path" ref="gr"></audio>
                                <v-icon @click.prevent="playAudio('gr')"
                                        color="green darken-1" v-if="!isPlayingCypriotGreek">fas fa-volume-up</v-icon>
                                <v-icon color="orange darken-4" v-else>fas fa-pause</v-icon>
                            </span>
                        </p>
                        <p class="helper">{{ $t('frontEnd.cypriotGreek') }}</p>
                    </div>
                    <div>

                        <p class="bigger">{{phrase.cypriot_turkish_phrase}}
                            <span class="mx-3 no-print" v-if="phrase.cypriot_turkish_audiotrack">
                                <audio :src="phrase.cypriot_turkish_audiotrack.path" ref="tr"></audio>
                                <v-icon @click.prevent="playAudio('tr')"
                                        color="green darken-1" v-if="!isPlayingCypriotTurkish">fas fa-volume-up</v-icon>
                                <v-icon color="orange darken-4" v-else>fas fa-pause</v-icon>
                            </span>
                        </p>
                        <p class="helper">{{ $t('frontEnd.cypriotTurkish') }}</p>
                    </div>
                    <div>

                        <p class="bigger">{{phrase.standard_turkish_phrase}}
                            <span class="mx-3 no-print" v-if="phrase.standard_turkish_audiotrack">
                                <audio :src="phrase.standard_turkish_audiotrack.path" ref="str"></audio>
                                <v-icon @click.prevent="playAudio('str')"
                                        color="green darken-1"
                                        v-if="!isPlayingStandardTurkish">fas fa-volume-up</v-icon>
                                <v-icon color="orange darken-4" v-else>fas fa-pause</v-icon>
                            </span>
                        </p>
                        <p class="helper">{{ $t('frontEnd.standardTurkish') }}</p>
                    </div>
                </section>

            </v-col>
            <v-col class="no-print" cols="12" sm="6" v-if="phrase.photo">
                <v-img :alt="phrase.english_phrase" :src="phrase.photo.path" contain max-height="300px"
                       max-width="500px"></v-img>
            </v-col>

            <v-col class="d-block d-sm-none no-print" cols="12">
                <social-sharing :description="description"
                                :hashtags="hashtags"
                                :quote="quote"
                                :title="title"
                                :twitter-user="twitterUser"
                                :url="url"
                                inline-template>
                    <div class="social-sharing-media">
                        <network class="mr-2" network="facebook">
                            <v-btn color="grey lighten-3" elevation="2" fab small>
                                <v-icon color="light-blue darken-2" small>fab fa-facebook</v-icon>
                            </v-btn>
                        </network>
                        <network class="mr-2" network="twitter">
                            <v-btn color="grey lighten-3" elevation="2" fab small>
                                <v-icon color="light-blue lighten-3" small>fab fa-twitter</v-icon>
                            </v-btn>
                        </network>
                        <network class="mr-2" network="telegram">
                            <v-btn color="grey lighten-3" elevation="2" fab small>
                                <v-icon color="light-blue lighten-1" small>fab fa-telegram</v-icon>
                            </v-btn>
                        </network>
                        <network class="mr-2" network="whatsapp">
                            <v-btn color="grey lighten-3" elevation="2" fab small>
                                <v-icon color="green accent-3" small>fab fa-whatsapp</v-icon>
                            </v-btn>
                        </network>
                        <network class="mr-2" network="linkedin">
                             <v-btn color="grey lighten-3" elevation="2" fab small>
                                <v-icon  color="blue darken-1" small>fab fa-linkedin</v-icon>
                            </v-btn>
                        </network>
                    </div>
                </social-sharing>
            </v-col>


        </v-row>
    </v-main>
</template>

<script>

    export default {
        name: "PhraseDetails",
        props: [
            'phrase'
        ],
        data() {
            return {
                title: 'HCNM Phrasebook',
                description: 'Hey! Look what I found on',
                hashtags: 'hcnm',
                twitterUser: 'oscehcnm',
                quote: 'Hey! Look what I found on',
                url: window.location.href,
                isPlayingStandardGreek: false,
                isPlayingCypriotGreek: false,
                isPlayingStandardTurkish: false,
                isPlayingCypriotTurkish: false
            }
        },
        methods: {
            playAudio(lang) {
                switch (lang) {
                    case 'sgr':
                        this.isPlayingStandardGreek = true;
                        break;
                    case 'gr':
                        this.isPlayingCypriotGreek = true;
                        break;
                    case 'str':
                        this.isPlayingStandardTurkish = true;
                        break;
                    case 'tr':
                        this.isPlayingCypriotTurkish = true;
                        break;
                }
                this.$refs[lang].play();
                this.$refs[lang].onended = () => {
                    switch (lang) {
                        case 'sgr':
                            this.isPlayingStandardGreek = false;
                            break;
                        case 'gr':
                            this.isPlayingCypriotGreek = false;
                            break;
                        case 'str':
                            this.isPlayingStandardTurkish = false;
                            break;
                        case 'tr':
                            this.isPlayingCypriotTurkish = false;
                            break;
                    }
                };


            }
        }
    }
</script>

<style scoped>

    .bigger {
        font-size: 1.4rem;
        color: #555555;
        margin-bottom: 6px;
    }

    .helper {
        color: #87868a;

    }

</style>
