<template>
    <v-card
        :style="styles"
        v-bind="$attrs"
        v-on="$listeners"
        :flat="isFlat"
        :elevation="elevation"
    >
        <Offset
            :full-width="fullWidth"
            :inline="inline"
            :offset="offset"
            v-if="hasOffset"
        >
            <v-card
                :class="`${colorText}`"
                :color="color"
                class="v-card--material__header"
                dark
                :elevation="elevation"
                v-if="!$slots.offset"

            >
                <slot
                    name="header"
                    v-if="!title && !text"
                />
                <span v-else>
          <h4
              class="title"
              v-text="title"></h4>
          <p
              v-if="typeof text == undefined"
              class="category font-weight-thin"
              v-text="text"></p>
        </span>
            </v-card>
            <slot
                name="offset"
                v-else
            />
        </Offset>

        <v-card-text>
            <slot/>
        </v-card-text>

        <v-divider
            class="mx-3"
            v-if="$slots.actions"
        />

        <v-card-actions v-if="$slots.actions">
            <slot name="actions"/>
        </v-card-actions>
    </v-card>
</template>

<script>
    import Offset from './Offset';

    export default {
        components: {Offset},
        inheritAttrs: false,
        name: 'Card',

        props: {
            colorText: {
                type: String,
                default: ''
            },
            isFlat: {
                type:Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'secondary'
            },
            elevation: {
                type: [Number, String],
                default: 0
            },
            inline: {
                type: Boolean,
                default: false
            },
            fullWidth: {
                type: Boolean,
                default: false
            },
            offset: {
                type: [Number, String],
                default: 24
            },
            title: {
                type: String,
                default: undefined
            },
            text: {
                type: String,
                default: undefined
            }
        },

        computed: {
            hasOffset() {
                return this.$slots.header ||
                    this.$slots.offset ||
                    this.title ||
                    this.text
            },
            styles() {
                if (!this.hasOffset) return null;

                return {
                    marginBottom: `${this.offset}px`,
                    marginTop: `${this.offset * 2}px`
                }
            }
        }
    }
</script>

<style>
    .v-card--material__header.v-card {
        border-radius: 4px;
        padding: 12px 12px 8px 12px;
    }

    .category {
        font-size: 14px;
    }

    .v-card .v-divider {
        border-top: 1px solid #eee;
        margin-left: 20px !important;
        margin-right: 20px !important;
        margin-bottom: 1px;
    }

    .v-card .v-card__actions {
        margin: 0 20px 0;
        padding: 10px 0 10px;
        line-height: 22px;
    }

</style>
