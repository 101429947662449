<template>
    <v-col cols="12" sm="6" md="4" lg="3" >
        <button  @click="goTo(topic.id)" class="topic" :style="{ borderBottom: '5px solid #' + topic.color }">
            <v-icon :style="{ color: '#'+ topic.color, border: '2px solid #'+topic.color}" class="circle-icon">{{topic.icon}}</v-icon>
            <span>{{ $t('theme.'+topic.id) }}</span>
        </button>
    </v-col>
</template>

<script>
    export default {
        name: "TopicBox",
        props: ["topic"],
        methods: {
            goTo() {
                this.$router.push({ name: 'topic', params: { id : this.topic.id}})
            }
        }
    }
</script>

<style scoped>
    .topic {
        border-radius: 1px 1px 4px 4px;
        background-color: #eaeaea;
        padding: 15px 26px;
        width: 100%;
        opacity: 1;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    .circle-icon{
        /*background-color: white;*/
        border-radius: 40px;
        border: 1px solid grey;
        min-height: 40px !important;
        min-width: 40px !important;
        margin-right: 10px;
    }

    .topic span{
        margin-top: 2px;
        text-align: left;
        font-size: initial;
    }

</style>
