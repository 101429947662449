/*
    Defines the API route we are using.
*/
let api_url = '';

switch( process.env.NODE_ENV ){
  case 'staging':
      api_url = 'https://staging.kitap-kitapi.com/api';
  break;
  case 'production':
    api_url = 'https://kitap-kitapi.com/api';
  break;
  case 'local':
    api_url = 'http://phrasebook.me/api';
  break;
}


export const HCNM_CONFIG = {
  API_URL: api_url,
};
