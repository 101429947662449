/*
|-------------------------------------------------------------------------------
| VUEX modules/phrases.js
|-------------------------------------------------------------------------------
| The Vuex data store for the phrases
*/

import ApiDefinitions from "../api/definition.js";

/*
|-------------------------------------------------------------------------------
| DIFFERENT STATES FOR LOADING
|-------------------------------------------------------------------------------
| status = 0 -> No loading has begun
| status = 1 -> Loading has started
| status = 2 -> Loading completed successfully
| status = 3 -> Loading completed unsuccessfully
|-------------------------------------------------------------------------------
*/

export const definitions = {
    state: {
        definition: {},
        definitions: [],
        options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
        },
        totalItems: null,
        rowsPerPageItems: [25, 50, 100],
        entries: [],
        phraseModel: {},
        entriesAdmin: [],
        resultAdmin: null,
        disableFileInputField: false,
        disableExploreField: false,
        specialCharacterToAdd: "",
        search: "",
        phrase: {theme: {name: ''}},
        themes: [],
        glossary: [],
        definitionsFromTopic: {
            definitions: []
        },
        glossaryFromTopic: {},
        relatedPhrases: [],
        chartPhrases: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        chartGlossary: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        statPhrases: 0,
        statAudios: 0,
        statImages: 0,
        statPhrasesWithAudio: 0
    },
    actions: {
        // !! Administrator actions. JSON Web Token required, private request !! //

        /**
         * Load all the configuration of the pagination and the entries
         * for the Definitions page.
         */
        getDashboardData({commit}) {
            return new Promise((resolve => {
                ApiDefinitions.getDashboardData().then(response => {
                    commit('setPhrasesCount', response.data.phrasesCount);
                    commit('setGlossaryCount', response.data.termsCount);
                    commit('setStatAudios', response.data.audios);
                    commit('setStatImages', response.data.images);
                    commit('setStatPhrases', response.data.phrases);
                    commit('setStatPhrasesWithAudio', response.data.phrasesWithAudio);
                })
            }))
        },

        getAllDefinitionsPaginate({commit, state}, payload) {
            let page_number =
                payload === undefined ? state.options.page : payload.page;
            let rowsPerPage =
                payload === undefined
                    ? state.options.itemsPerPage
                    : payload.itemsPerPage;

            return new Promise((resolve, reject) => {
                ApiDefinitions.getAllDefinitionsPaginate({
                    page_number,
                    rowsPerPage
                }).then(
                    response => {
                        commit("loadAllDefinitionsPaginate", response.data);
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * Search the definition in all the languages through the
         * text field in the top of the table of Definitions.
         */
        getSearchFilter({commit, dispatch}, payload) {
            if (!payload) {
                dispatch("getAllDefinitionsPaginate");
                return;
            }
            return new Promise((resolve, reject) => {
                ApiDefinitions.getSearchFilter(payload).then(
                    response => {
                        commit("setSearchFilterResult", response.data);
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * Search the definition in all the languages through the
         * text field in the top of the table of Definitions.
         */
        getSearchFilterGlossary({commit, dispatch}, payload) {
            if (!payload) {
                dispatch("getGlossary");
                return;
            }
            return new Promise((resolve, reject) => {
                ApiDefinitions.getSearchFilterGlossary(payload).then(
                    response => {
                        commit("setSearchFilterResultGlossary", response.data);
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * Triggered when selecting a Definition to load all the data.
         */

        getDefinitionAdmin({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.getDefinitionAdmin(payload).then(
                    response => {
                        if (response.status === 200) {
                            commit("setDefinition", response.data.definition);
                            resolve(response.data.definition);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },

        /**
         * Bind to the Update button in Definition view. Update all the
         * fields of the Definition.
         */

        updateDefinition({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.updateDefinition(payload).then(
                    response => {
                        dispatch(
                            "getDefinitionAdmin",
                            response.data.updated.id
                        );
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * Generate a new Definition with the data filled in the form.
         */
        newDefinition({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.newDefinition(payload).then(
                    response => {
                        if (response.status === 201) {
                            dispatch("getAllDefinitionsPaginate");
                            resolve(response.data);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * UNLINK the image from a Definition. BUT NOT IS REMOVED from storage
         */
        removeImage({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.removeImage(payload).then(
                    response => {
                        dispatch(
                            "getDefinitionAdmin",
                            response.data.updated.id
                        );
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },

        /**
         * Delete a Definition, unlink the images associated
         * and REMOVE the audios linked
         */
        deleteDefinition({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.removeDefinition(payload).then(
                    response => {
                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * Link an audio with a Definition.
         */
        linkAudiotrack({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.link(payload).then(
                    response => {
                        dispatch("getDefinitionAdmin", response.data.id);
                        resolve(response.data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /**
         * Unlink an audio with a Definition.
         */
        unlinkAudio({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.unlinkAudio(payload).then(
                    response => {
                        dispatch(
                            "getDefinitionAdmin",
                            response.data.definition.id
                        );

                        resolve();
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },

        syncData({commit}) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.syncData().then(
                    response => {
                        resolve(response.data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        syncGlossary({commit}) {

            return new Promise((resolve, reject) => {

                ApiDefinitions.syncGlossary().then(
                    response => {
                        resolve(response.data)
                    }, error => {
                        reject(error)
                    }
                )
            })
        },

        getGlossary({commit}) {

            return new Promise((resolve, reject) => {
                ApiDefinitions.getGlossary().then(response => {
                    commit('setGlossary', response.data)
                }, error => {
                    reject(error)
                })
            })
        },




        // !! Public actions. JSON Web Token NOT required, public request !! //


        searchTypeahead({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.searchTypeahead(payload).then(
                    response => {
                        if (response.status === 200) {
                            commit("setEntries", response.data.entries);
                            resolve();
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        searchPhrase({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.searchPhrase(payload).then(
                    response => {
                        if (response.status === 200) {
                            commit('setPhrase', response.data[0])
                            resolve();
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        getAllTopics({commit}) {
            return new Promise((resolve) => {
                ApiDefinitions.getAllTopics().then(response => {
                    commit('setThemes', response.data)
                })
            })
        },
        getTopic({commit}, payload) {
            return new Promise((resolve, reject) => {
                ApiDefinitions.getTopic(payload).then(response => {
                    commit('setDefinitionsFromTopic', response.data)
                })
            })
        },

        getGlossaryTerms({commit}, payload) {
            return new Promise((resolve, rejecet) => {

                ApiDefinitions.getGlossaryTerms(payload).then(response => {
                    commit('setGlossaryTerms', response.data)
                }, error => {
                    rejecet(error)
                })
            })
        },

        relatedPhrases({commit}, payload) {

            return new Promise(((resolve, reject) => {

                ApiDefinitions.relatedPhrases(payload).then(res => {
                    commit('setRelatedPhrases', res.data)
                    resolve(res.data)
                }, error => {
                    reject(error)
                })
            }))
        }
    },

    mutations: {
        // !! Administrator mutations. JSON Web Token required, private request !! //

        // Set all the definitions and configuration for the definitions table.
        loadAllDefinitionsPaginate(state, payload) {
            state.definitions = payload.data;
            state.options.page = payload.current_page;
            state.options.itemsPerPage = payload.per_page;
            state.totalItems = payload.total;
        },

        setSearchFilterResult: (state, payload) => {
            state.definitions = payload;
        },
        setSearchFilterResultGlossary: (state, payload) => {
            state.glossary = payload;
        },

        setDefinition: (state, payload) => (state.definition = payload),

        updatedDefinition: (state, payload) => (state.definition = payload),

        setEntries: (state, payload) => (state.entries = payload),

        setTypeahead: (state, payload) => (state.phraseModel = payload),

        cleanTypeahead: state => (state.entries = []),

        setDialogNewDefinition: (state, payload) =>
            (state.dialogNewDefinition = payload),

        setDialogDeleteDefinition: (state, payload) =>
            (state.dialogDeleteDefinition = payload),

        setEntriesAdmin: (state, payload) => (state.entriesAdmin = payload),

        cleanTypeaheadAdmin: state => (state.entriesAdmin = []),

        setTypeaheadAdmin: (state, payload) => (state.resultAdmin = payload),

        setDisableFileInputField: (state, payload) =>
            (state.disableFileInputField = payload),

        setDisableExploreField: (state, payload) =>
            (state.disableExploreField = payload),

        setSpecialCharacterToAdd: (state, payload) => {
            if (state.search === null) {
                state.search = payload;
            } else {
                console.log(state.search)
                console.log(payload)
                state.search =  state.search + payload;
            }
        },

        setSearch: (state, payload) => (state.search = payload),

        setPhrase: (state, payload) => (state.phrase = payload),

        setThemes: (state, payload) => state.themes = payload,

        setGlossary: (state, payload) => state.glossary = payload,

        setDefinitionsFromTopic: (state, payload) => state.definitionsFromTopic = payload,

        setGlossaryTerms: (state, payload) => state.glossaryFromTopic = payload,

        setRelatedPhrases: (state, payload) => state.relatedPhrases = payload,

        setPhrasesCount: (state, payload) => state.chartPhrases = payload,

        setGlossaryCount: (state, payload) => state.chartGlossary = payload,

        setStatPhrases: (state, pay) => state.statPhrases = pay,

        setStatPhrasesWithAudio: (state, pay) => state.statPhrasesWithAudio = pay,

        setStatAudios: (state, pay) => state.statAudios = pay,

        setStatImages: (state, pay) => state.statImages = pay,
    },

    getters: {
        totalItems: state => state.totalItems,

        getPage: state => state.options.page,

        getOptions: state => state.options,

        getDefinitions: state => state.definitions,

        getRowsPerPageItems: state => state.rowsPerPageItems,

        getDefinition: state => state.definition,

        getEntries: state => state.entries,

        getPhraseModel: state => state.phraseModel,

        dialog: state => state.dialogNewDefinition,

        deleteDialog: state => state.dialogDeleteDefinition,

        getEntriesAdmin: state => state.entriesAdmin,

        getDisableFileInputField: state => state.disableFileInputField,

        getDisableExploreField: state => state.disableExploreField,

        specialCharacterToAdd: state => state.specialCharacterToAdd,

        getSearch: state => state.search,

        getPhrase: state => state.phrase,

        getThemes: state => state.themes,

        getGlossaryList: state => state.glossary,

        getDefinitionsFromTopic: state => state.definitionsFromTopic,

        getGlossaryFromTopic: state => state.glossaryFromTopic,

        getRelatedPhrases: state => state.relatedPhrases,

        getChartPhrases: state => state.chartPhrases,

        getChartGlossary: state => state.chartGlossary,

        getStatPhrases: state => state.statPhrases,

        getStatPhrasesWithAudio: state => state.statPhrasesWithAudio,

        getStatAudio: state => state.statAudios,

        getStatImages: state => state.statImages,
    }
};
