<template>
    <v-main fill-height>
        <v-row justify="center">
            <v-col cols="12">
                <v-card style="padding: 0 20px;">
                    <v-card-text>
                        <PhraseDetails :phrase="phrase"/>

                        <RelatedPhrases :related="relatedPhrases" :theme="phrase.theme"/>
                        <v-row>
                            <v-col>
                                <v-col class="ma-0 pa-0 text-right" cols="12">
                                    <v-btn @click="printPhrase" class="mr-4" color="blue-grey lighten-4" depressed
                                           small>
                                        <v-icon class="mr-2" color="blue-grey darken-4" small>fas fa-print</v-icon>
                                        {{ $t('frontEnd.print') }}
                                    </v-btn>
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>
    import PhraseDetails from "../../components/frontend/details/PhraseDetails";
    import RelatedPhrases from "../../components/frontend/details/RelatedPhrases";

    export default {
        name: "Details",
        data() {
            return {
                phraseId: this.$route.params.phrase
            }
        },
        components: {PhraseDetails, RelatedPhrases},
        mounted() {
            this.$store.dispatch("Definitions/searchPhrase", this.phraseId).then(res => {

                this.$store.dispatch("Definitions/relatedPhrases", {
                    phraseId: this.phraseId,
                    themeId: this.phrase.theme.id
                })
                    .then(res => {
                    })
            })
        },
        computed: {
            phrase() {
                return this.$store.getters["Definitions/getPhrase"]
            },

            relatedPhrases() {
                return this.$store.getters['Definitions/getRelatedPhrases']
            }
        },
        methods: {
            printPhrase() {
                window.print();
            }
        }
    }
</script>

<style scoped>


</style>
