<template>
    <v-card style="padding: 0 20px;">
        <v-card-text>
            <!--<p style="font-size:2rem">{{ $t('frontEnd.search') }}</p>-->
            <ComboBoxComponent/>
        </v-card-text>
    </v-card>
</template>

<script>
    import ComboBoxComponent from './ComboBox-component'

    export default {
        name: "Search",
        components: {ComboBoxComponent}
    }
</script>

<style scoped>

</style>
