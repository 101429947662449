/*
    Imports the Phrasebook API URL from the config.
*/

export default {
    getAllDefinitionsPaginate: function (payload) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.token;

        return axios.get(
            "/admin/getAllDefinitionsPaginate?page=" +
            payload.page_number +
            "&rowsPerPage=" +
            payload.rowsPerPage +
            "&sortBy=" +
            payload.sortBy +
            "&sortDesc=" +
            payload.sortDesc
        );
    },

    getSearchFilter: function (payload) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.token;

        return axios.get("/admin/searchFilter?word=" + payload);
    },

    getDefinitionAdmin: function (payload) {
        return axios.get("/admin/getDefinition?id=" + payload);
    },

    updateDefinition: function (payload) {
        return axios.put(
            "/admin/updateDefinition?id=" + payload.definition.id,
            payload
        );
    },

    getSearchFilterGlossary: (payload) => axios.get("/admin/searchFilterGlossary?word=" + payload),

    newDefinition: function (payload) {
        return axios.post("/admin/newDefinition", payload);
    },

    removeImage: function (payload) {
        return axios.put("/admin/removeImageFromDefinition?id=" + payload);
    },

    searchTypeaheadImages: payload => {
        return axios.get(
            "/admin/search/" + payload.locale + "/" + payload.keyword
        );
    },
    removeDefinition: function (payload) {
        return axios.delete("/admin/deleteDefinition/" + payload);
    },

    link: payload => {
        return axios.put("/admin/linkAudio", payload);
    },

    unlinkAudio: payload => axios.put("/admin/unlinkAudio", payload),

    // Don't need JWT Authorization

    searchTypeahead: payload => {
        return axios.get("/search/" + payload.keyword);
    },

    searchPhrase: payload => {
        return axios.get("/search/phrase/" + payload);
    },

    getAllTopics: () => {
        return axios.get("/themes")
    },


    getTopic: (id) => {
        return axios.get("theme/" + id)
    },
    getGlossaryTerms: (id) => {
        return axios.get("glossary/" + id)
    },
    getGlossary: () => axios.get("/admin/getGlossary"),

    syncData: () => axios.get("/admin/syncData"),

    syncGlossary: () => axios.get("/admin/syncGlossary"),

    relatedPhrases: (payload) => axios.get('/relatedPhrases/' + payload.phraseId + '/' + payload.themeId),

    getDashboardData: () => axios.get('/admin/getDashboardData')

};
