<template>
    <v-main>
        <v-row justify="center">
            <v-col cols="12" >
                <v-card  style="padding: 0 20px;">
                    <v-card-title >
                        <p class="line-color" :style="{ borderBottom: '9px solid #' + definitions.color }">{{ $t('theme.'+definitions.id) }}</p>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="transpa">
                            <thead>

                            <tr>
                                <th class="text-left table-header ">{{ $t('frontEnd.standardGreek') }}</th>
                                <th class="text-left table-header ">{{ $t('frontEnd.cypriotGreek') }}</th>
                                <th class="text-left table-header ">{{ $t('frontEnd.cypriotTurkish') }}</th>
                                <th class="text-left table-header ">{{ $t('frontEnd.standardTurkish') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <router-link style="cursor: pointer" :to="{name: 'details-phrase', params: { phrase: item.id}}" tag="tr" :key="item.id" v-for="item in definitions.definitions">
                                <td>
                                <span>
                                    {{ item.standard_greek_phrase }}
                                </span>
                                </td>
                                <td>
                                <span>
                                    {{ item.cypriot_greek_phrase }}
                                </span>
                                </td>
                                <td>
                                <span>
                                    {{ item.cypriot_turkish_phrase }}
                                </span>
                                </td>
                                <td>
                                <span>
                                    {{ item.standard_turkish_phrase }}
                                </span>
                                </td>
                            </router-link>
                            </tbody>
                        </v-simple-table>
                        <div class="mt-5 text-center">
                        <v-btn class="mx-3" :to="{ name: 'glossary', params: { id: definitions.id }}">{{ $t('frontEnd.glossary') }}</v-btn>
                        <v-btn class="mx-3" :to="{ name: 'alphabet' }">{{ $t('frontEnd.alphabet') }}</v-btn>
                        <v-btn class="mx-3" v-if="topicId != 11" :to="{ name: 'grammar' }">{{ $t('frontEnd.grammar') }}</v-btn>
                        </div>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-main>

</template>

<script>
    import CustomCard from "../../components/backend/Custom-Cards/CustomCard";

    export default {
        name: "Topic",
        components: {CustomCard},
        data() {
            return {
                topicId: this.$route.params.id
            }
        },
        mounted() {
            this.$store.dispatch('Definitions/getTopic', this.topicId)
        },
        computed: {
            definitions() {
                return this.$store.getters['Definitions/getDefinitionsFromTopic']
            }
        },

    }
</script>

<style scoped>

    .line-color{
        border-radius: 1px 1px 8px 8px;
        padding: 15px 0;
        width: 100%;
        opacity: 1;
        font-size:2rem;
        color: #555555;
    }

    .theme--light.v-select-list.v-card{
        padding: 0px;
    }
</style>
