import VueI18n from 'vue-i18n'
import Vue from 'vue';
import { defaultLocale, languages } from '../../locales/index.js'
const messages = Object.assign(languages);

Vue.use(VueI18n);

const locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

const opts = {
    locale: (locale.trim().length && locale != "/") ? locale : defaultLocale,
    fallbackLocale: 'en',
    messages
};

export default new VueI18n(opts);
