<template>
    <v-row>
        <v-col>
            <v-bottom-sheet v-model="sheet" content-class="container" hide-overlay>
                <v-sheet :hide-overlay="true" class="text-center py-5 px-3 shadow-xl" height="auto">

                    <v-row class="flex-wrap" justify="center" no-gutters
                    >
                        <v-col class="pb-2 pt-2" cols="12" sm="6">
                            <p class="title">{{ $t('frontEnd.greekCharacters') }}</p>
                            <span :key="index" v-for="(char, index) in characters.greek">
                                <v-btn
                                    :class="{'text-none': !switchCaseGr}"
                                    :color="colorCaseGr"
                                    @click="addCharacter(char)"
                                    class="ma-1"
                                    small
                                >{{ char }}</v-btn>
                            </span>
                        </v-col>

                        <v-col class="pb-2 pt-2" cols="12" sm="6">

                            <p class="title">{{ $t('frontEnd.turkishCharacters') }}</p>

                            <span :key="index" v-for="(char, index) in characters.turkish">
                                <v-btn
                                    :class="{'text-none': !switchCaseTr}"
                                    :color="colorCaseTr"
                                    @click="addCharacter(char)"
                                    class="ma-1"
                                    small
                                >{{ char }}</v-btn>
                            </span>

                        </v-col>
                    </v-row>
                </v-sheet>
            </v-bottom-sheet>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "SpecialCharacters",
    props: {
        sheetBoolean: Boolean,
        source: {
            type: String,
            default: "definitions"
        },
        maxLength: {
            type: Number | Boolean,
            default: false
        }
    },
    data() {
        return {
            switchCaseGr: false,
            switchCaseTr: false,
            characters: {
                greek: [
                    "α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ", "λ", "μ", "ν", "ξ", "ο", "π", "ρ", "σ", "ς",
                    "τ", "υ", "φ", "χ", "ψ", "ω"
                ],
                turkish: ["ç", "ğ", "ı", "ö", "ş", "ü"]
            }
        };
    },
    computed: {
        exerciseText() {
            return this.$store.state.Exercises.answerInput;
        },
        colorCaseGr() {
            return this.switchCaseGr === false ? "grey lighten-2" : "blue lighten-3";
        },
        colorCaseTr() {
            return this.switchCaseTr === false ? "grey lighten-2" : "blue lighten-3";
        },
        sheet: {
            get() {
                return this.sheetBoolean
            },
            set() {
                this.$emit('closeSheet')
            }
        }
    },
    methods: {
        addCharacter(val) {
            if (this.source === "definitions") {
                this.$store.commit("Definitions/setSpecialCharacterToAdd", val);
            } else if (this.source === "exercises") {
                if (this.maxLength && this.exerciseText.length < this.maxLength) {
                    this.$store.commit("Exercises/ADD_SPECIAL_CHARACTER", val);
                }
            }
        },
        letterCase(lang) {
            if (lang === 'gr') {
                this.switchCaseGr = !this.switchCaseGr;
            } else {
                this.switchCaseTr = !this.switchCaseTr;

            }
        },
    }
};
</script>

<style scoped>
</style>
