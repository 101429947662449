<template>
    <v-app class="main-background">
        <toolbar-component/>

        <svg class="no-print" id="wave" viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0,192L48,181.3C96,171,192,149,288,149.3C384,149,480,171,576,202.7C672,235,768,277,864,282.7C960,288,1056,256,1152,208C1248,160,1344,96,1392,64L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                fill="#c5c5c5"
                fill-opacity="1"></path>
        </svg>

        <vue-page-transition name="fade">
            <v-main>
                <v-container class="pt-0">
                    <router-view :key="$route.fullPath"></router-view>
                </v-container>
            </v-main>
        </vue-page-transition>

        <footer-component/>
        <cookie-law class="no-print"  theme="dark-lime">
            <div slot-scope="props" style="width: 100%">

                <v-row>
                    <v-col cols="9">
                        <p style="font-size:0.9rem">{{ $t('frontEnd.cookies') }}</p>
                    </v-col>
                    <v-col cols="3" class="text-right">
                        <v-btn @click="props.accept"  color="green darken-1" class="white--text text-none" ><span>{{ $t('frontEnd.Iaccept') }}</span></v-btn>

                        <v-btn @click="props.close"  color="red darken-1" class="white--text text-none"><span>{{ $t('frontEnd.ignore') }}</span></v-btn>
                    </v-col>
                </v-row>


            </div>
        </cookie-law>

    </v-app>
</template>

<script>
    import CookieLaw from 'vue-cookie-law'
    import FooterComponent from "../components/frontend/global/Footer-component";
    import ToolbarComponent from "../components/frontend/global/Toolbar-component";

    export default {
        components: {
            FooterComponent,
            ToolbarComponent,
            CookieLaw
        },

    };
</script>

<style>
    #wave {
        position: absolute;
        bottom: 0;
    }


</style>
