<template>

    <v-row justify="center">
        <v-col cols="12" class="d-none">

            <SpecialCharacters  :sheetBoolean="showKeyboard" @closeSheet="closeSheet"/>

        </v-col>
        <v-col cols="12" md="6">

            <v-combobox
                :hide-no-data="true"
                :items="entries"
                :loading="isLoading"
                :menu-props="{  maxHeight: '300', maxWidth: 'auto', value: autoselectMenu, offsetY: true , closeOnContentClick:true , zIndex: 1}"
                :placeholder="$t('frontEnd.howSearch')"
                :search-input.sync="search"
                @click:append.prevent="onOpenKeyboard"
                @input="modelChanged"
                append-icon="fas fa-keyboard"
                autocomplete="off"
                autofocus
                :no-data-text="$t('frontEnd.noData')"
                outlined
                prepend-inner-icon="fas fa-search"
                v-model="model"
                color="rgb(19, 96, 150)"
            >

                <template v-slot:item="{ index, item }">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-chip
                            label
                            small
                        >
                            {{ item.lang }}
                        </v-chip>
                    </v-list-item-action>
                </template>

            </v-combobox>

        </v-col>
    </v-row>

</template>

<script>

    import SpecialCharacters from "./SpecialCharacters";

    export default {
        components: {SpecialCharacters},
        data: () => ({
            isLoading: false,
            model: null,
            autoselectMenu: false,
            showKeyboard: false
        }),

        watch: {
            search(val, prev) {
                if (this.isLoading) return;
                if (val) {
                    this.isLoading = true;
                    this.$store.commit("Definitions/setSearch", val);
                    this.$store.dispatch("Definitions/searchTypeahead", {
                        keyword: val
                    })
                        .then(() => {
                            this.isLoading = false;
                        });
                } else {
                    this.autoselectMenu = false;
                    this.$store.commit("Definitions/cleanTypeahead");
                }
            },
            entries() {
                if (this.entries.length > 1) {
                    this.autoselectMenu = true;
                } else if (this.entries.length === 0) {
                    this.autoselectMenu = false;
                }
            }
        },

        methods: {
            closeSheet() {
                this.showKeyboard = false;
            },
            modelChanged() {
                if (typeof this.model == 'object') {
                    let cleanId = this.model.value.split('-')[0];
                    this.$router.push({name: 'details-phrase', params: {phrase: cleanId}})
                }
            },
            onOpenKeyboard() {
                this.autoselectMenu = false;
                this.showKeyboard = !this.showKeyboard;
                setTimeout(() => {
                    this.autoselectMenu = true;
                }, 1000)

            }

        },
        computed: {
            entries() {
                return this.$store.getters["Definitions/getEntries"];
            },
            search: {
                get() {
                    return this.$store.getters["Definitions/getSearch"];
                },
                set(val) {
                    this.$store.commit("Definitions/setSearch", val);
                }
            }
        }
    };
</script>

<style scoped>

    #app > div.v-menu__content.theme--light.menuable__content__active.v-autocomplete__content {
        z-index: 1 !important;
    }

</style>
