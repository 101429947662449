// Import third party libraries
require('./bootstrap');
// Import our custom Vuetify plugin
import vuetify from './plugins/vuetify';
// Import i18n
import i18n from './plugins/i18n';
// Import router
import router from './routes';
// Import main Vuex file
import store from './store/store';


// Main entry component of the application
Vue.component('app-root', require('./layouts/App.vue').default);
// Skip message
Vue.config.productionTip = false;

const app = new Vue({
    i18n,
    vuetify,
    el: '#app',
    router,
    store
});
