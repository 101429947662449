export default {
    getExercise: payload => axios.get("/admin/exercises/" + payload),

    getExercises: payload => {
        return axios.get(
            "/admin/exercises/list/" + payload.type + "/" + payload.themeId,
            {
                headers: {"Content-Type": "application/json"}
            }
        );
    },

    createExercises: payload => axios.post("/admin/exercises", payload),

    updateExercise: payload => axios.put("/admin/exercises/update", payload),

    updateOrder: payload => axios.put("/admin/exercises/updateOrder", payload),

    deleteExercise: payload => axios.delete("/admin/exercises/delete/" + payload),

    addWrongAnswers: payload => axios.post("/admin/exercises/wrongAnswers", payload),

    storeAudio: payload => axios.post('/admin/exercises/storeAudio', payload),

    deleteAudio: payload => axios.delete('/admin/exercises/deleteAudio/' + payload),

    storeWrongAnswer: payload => axios.post('/admin/exercises/storeWrongAnswer', payload),

    updateWrongAnswers: payload => axios.post('/admin/exercises/updateWrongAnswers', payload),

    deleteWrongAnswer: payload => axios.delete('/admin/exercises/deleteWrongAnswer/' + payload),

    insertWrongAnswer: payload => axios.post('/admin/exercises/insertWrongAnswer', payload),

    uploadImage: payload => axios.post('/admin/exercises/uploadImage', payload),

    deleteImage: payload => axios.delete('/admin/exercises/deleteImage/' + payload),

    getExerciseList: payload => axios.get('/exercises/list/' + payload.type +'/'+ payload.themeId),

    getExerciseIdsList: payload => axios.get('/exercises/list/ids')

};
