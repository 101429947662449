<template>
    <div
        :style="styles"
        :class="classes"
        class="v-offset"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            fullWidth: {
                type: Boolean,
                default: false
            },
            offset: {
                type: [Number, String],
                default: 0
            }
        },

        computed: {
            classes () {
                return {
                    'v-offset--full-width': this.fullWidth
                }
            },
            styles () {
                return {
                    top: `-${this.offset}px`,
                    marginBottom: `-${this.offset}px`
                }
            }
        }
    }
</script>

<style>
    .v-offset {
        margin: 0 auto;
        max-width: calc(100% - 50px);
        position: relative;
    }
    .v-offset--full-width {
        max-width: 100%;
    }
    .v-card--material__header.v-card {
        border-radius: 4px !important;
        padding: 12px 12px 8px 12px ;
    }
</style>
