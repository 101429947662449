<template>
    <v-card  style="padding: 0 20px;">
        <v-card-text>
            <p class="mt-5" style="font-size:2rem">{{ $t('frontEnd.exploreTopics') }}</p>
            <v-row class="d-flex">
                    <TopicBox  :key="index" :topic="topic" class="" v-for="(topic, index) of themes"/>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import TopicBox from "./TopicBox";

    export default {
        name: "Topics",
        components: {TopicBox},
        mounted(){
            this.$store.dispatch('Definitions/getAllTopics');
        },
        computed: {
          themes(){
              return this.$store.getters["Definitions/getThemes"];
          }
        }
    }
</script>

<style scoped>

</style>
