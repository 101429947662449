import Vue from "vue";
import VueRouter from "vue-router";
import store from './store/store.js'
import { defaultLocale } from '../locales/index.js'

Vue.use(VueRouter);

import Frontend from "./layouts/Frontend";
import Home from "./pages/Frontend/Home";
import Welcome from "./pages/Frontend/Welcome.vue"
import Details from "./pages/Frontend/Details";
import TopicTable from "./pages/Frontend/TopicTable";


const routes = [
    {
        path: "/",
        component: Frontend,
        meta: {
            title: 'Home Page - Kitap Kitapi',
            metaTags: [
                {
                    name: 'description',
                    content: 'Online phrasebook with Standard-Cypriot Turkish / Standard-Cypriot Greek definitions.'
                },
                {
                    property: 'og:description',
                    content: 'Online phrasebook with Standard-Cypriot Turkish / Standard-Cypriot Greek definitions.'
                }
            ]
        },
        children: [
            {
                path: "",
                name: "home",
                component: Home
            },
            {
                path: "welcome",
                name: "welcome",
                component: Welcome,
                meta: { title: "Welcome Page - Kitap Kitapi" }
            },
            {
                path: "exercises",
                meta: { title: "Exercises - Kitap Kitapi" },
                component: Vue.component("exercises", () => import("./pages/Frontend/Exercises.vue")),
                children: [
                    {
                        path: "",
                        name: "SelectLanguage",
                        component: Vue.component('SelectLanguage', () => import('./pages/Frontend/Exercises/SelectLanguage'))

                    },
                    {
                        path: "completed",
                        name: "ExercisesCompleted",
                        component: Vue.component('ExercisesCompleted', () => import('./pages/Frontend/Exercises/ExerciseTypeFinished'))
                    },
                    {
                        path: ":lang(greek|turkish)",
                        name: "SelectTheme",
                        component: Vue.component('SelectTheme', () => import('./pages/Frontend/Exercises/SelectTheme'))
                    },
                    {
                        path: ":lang(greek|turkish)/:themeId([1-9]|1[0-3])",
                        name: "SelectType",
                        component: Vue.component('SelectType', () => import('./pages/Frontend/Exercises/SelectType'))
                    },
                    {
                        path: ':lang(greek|turkish)/:themeId([1-9]|1[0-3])/:exerciseType([1-4])/exercise',
                        name: 'exercisesType',
                        component: Vue.component('exercisesType', () => import("./pages/Frontend/Exercises/ExerciseType")),
                    }
                ]

            },
            {
                path: "grammar/:id",
                name: "grammar",
                component: Vue.component(
                    "grammar",
                    () => import("./pages/Frontend/Grammar.vue")
                ),
                meta: { title: "Grammar Page - Kitap Kitapi" }

            },
            {
                path: "alphabet",
                name: "alphabet",
                component: Vue.component(
                    "alphabet",
                    () => import("./pages/Frontend/Alphabet.vue")
                ),
                meta: { title: "Alphabet Page - Kitap Kitapi" }

            },
            {
                path: "contact-us",
                name: "contactForm",
                component: Vue.component(
                    "contactForm",
                    () => import("./pages/Frontend/ContactForm.vue")
                ),
                meta: { title: "Contact us - Kitap Kitapi" }

            },
            {
                path: "about-us",
                name: "aboutUs",
                component: Vue.component(
                    "aboutUs",
                    () => import("./pages/Frontend/AboutUs.vue")
                ),
                meta: { title: "About us - Kitap Kitapi" }

            },
            {
                path: "details/:phrase",
                name: "details-phrase",
                component: Details,
                meta: { title: "Definition - Kitap Kitapi" }

            },
            {
                path: "topic/:id",
                name: "topic",
                component: TopicTable
            },
            {
                path: "glossary/:id",
                name: "glossary",
                component: Vue.component(
                    "glossary",
                    () => import("./pages/Frontend/GlossaryTable.vue")
                )
            }
        ]
    },
    {
        path: "/administrator",
        component: Vue.component(
            "Administrator",
            () => import("./layouts/Backend.vue")
        ),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "",
                name: "Dashboard",
                meta: {
                    requiresAuth: true,
                    title: "Dashboard - Kitap Kitapi"
                },
                component: Vue.component(
                    "Dashboard",
                    () => import("./pages/Backend/Dashboard.vue")
                ),

            },
            {
                path: "definitions",
                name: "Definitions",
                meta: {
                    requiresAuth: true,
                    title: "Definitions - Kitap Kitapi"
                },
                component: Vue.component(
                    "Definitions",
                    () => import("./pages/Backend/Definitions.vue")
                )
            },
            {
                path: "exercises",
                name: "Exercises",
                meta: {
                    requiresAuth: true,
                    title: "Exercises - Kitap Kitapi"
                },
                component: Vue.component(
                    "Exercises",
                    () => import("./pages/Backend/Exercises/Exercises")
                )
            },

            {
                path: "exercises/:type/:theme_id",
                name: "ExerciseList",
                meta: {
                    requiresAuth: true,
                    title: "Exercises - Kitap Kitapi"
                },
                component: Vue.component(
                    "ExerciseList",
                    () => import("./pages/Backend/Exercises/ExerciseList")
                )
            },
            {
                path: "exercises/:type/:theme_id/create",
                name: "ExerciseCreate",
                meta: {
                    requiresAuth: true,
                    title: "Create Exercise - Kitap Kitapi"
                },
                component: Vue.component(
                    "ExerciseCreate",
                    () => import("./pages/Backend/Exercises/ExerciseCreate")
                )
            },
            {
                path: "exercises/:type/:theme_id/update/:exercise_id",
                name: "ExerciseUpdate",
                meta: {
                    requiresAuth: true,
                    title: "Exercise - Kitap Kitapi"
                },
                component: Vue.component(
                    "ExerciseUpdate",
                    () => import("./pages/Backend/Exercises/ExerciseUpdate")
                )
            },

            {
                path: "definition/:id",
                name: "Definition",
                meta: {
                    requiresAuth: true
                },
                component: Vue.component(
                    "Definition",
                    () => import("./pages/Backend/Definition.vue")
                )
            },
            {
                path: "pictures",
                name: "Pictures",
                meta: {
                    requiresAuth: true,
                    title: "Illustrations - Kitap Kitapi"
                },
                component: Vue.component(
                    "Pictures",
                    () => import("./pages/Backend/Pictures.vue")
                )
            },

            {
                path: "users",
                name: "Users",
                meta: {
                    requiresAuth: true,
                    title: "Users - Kitap Kitapi"
                },
                component: Vue.component(
                    "Users",
                    () => import("./pages/Backend/Users.vue")
                )
            },
            {
                path: "users/:id",
                name: "User",
                meta: {
                    requiresAuth: true
                },
                component: Vue.component(
                    "User",
                    () => import("./pages/Backend/User.vue")
                )
            },
            {
                path: "themes",
                name: "themes",
                meta: {
                    requiresAuth: true,
                    title: "Topics - Kitap Kitapi"
                },
                component: Vue.component(
                    "themes",
                    () => import("./pages/Backend/Themes.vue")
                )
            },
            {
                path: "glossary",
                name: "glossaryTable",
                meta: {
                    requiresAuth: true,
                    title: "Glossary - Kitap Kitapi"
                },
                component: Vue.component(
                    "glossaryTable",
                    () => import("./pages/Backend/Glossary.vue")
                )
            },
        ]
    },
    {
        path: "/auth/login",
        name: "Login",
        component: Vue.component(
            "Login",
            () => import("./layouts/Login")
        )
    },
    // {
    //     path: '*',
    //     component: Vue.component('notFoundComponent', () => import("./pages/Frontend/NotFound"))
    // }
];

const locale = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');

const router = new VueRouter({
    base: (locale.trim().length && locale != "/") ? '/' + locale : '/' + defaultLocale,
    mode: 'history',
    routes
});

// Global router guard to check routes which requires auth can't be accessed

router.beforeEach(async (to, from, next) => {
    setMetaTags(to, from)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['Users/getLoggedIn']) {

            next();
        }
        await store.dispatch('Users/checkAuth')
            .then(() => {
                next();
            })
            .catch(() => {
                next({
                    path: '/auth/login',
                    query: { redirect: to.fullPath }
                })
            });

    } else {
        next()
    }
});

function setMetaTags(to, from) {
    // Change meta title
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return;

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
}


export default router;
