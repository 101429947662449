<template>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" class="text-center">
        <v-btn
          :class="[ $i18n.locale === lang.key ? 'v-btn--active' : '']"
          :key="lang.key"
          @click="changeLocale(lang.key)"
          color="info"
          text
          v-for="lang in languages"
          class="text-none"
        >
          <FlagComponent :iso="lang.key" class="mr-3"></FlagComponent>
          {{$t("home." +lang.label)}}
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>
import FlagComponent from '../global/flag-component'
export default {
  name: "Change-language-component",
  components: {FlagComponent},
  data() {
    return {
      languages: [
        { key: "gr", label: "greek" },
        { key: "tr", label: "turkish" }
      ]
    };
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
      this.$store.commit("Definitions/setSearch", "");
    }
  }
};
</script>

<style scoped>
</style>
