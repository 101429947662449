<template>

<span class="flag-icon d-flex align-start mt-1">
    <v-img :src="`/storage/flags/${iso}.svg`" width="22px" height="16px" ></v-img>
</span>

</template>
<script>
export default {
    props: {
        iso :{
            type: String,
            required: true
        }
    }
    
}
</script>
<style scoped>
.flag-icon {
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}
</style>