require("es6-promise").polyfill();

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { definitions } from "../modules/definitions.js";
import { users } from "../modules/users.js";
import { images } from "../modules/images.js";
import { audiotracks } from "../modules/audiotracks";
import { exercises } from "../modules/exercises";

export default new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',

    modules: {
        Definitions: {
            namespaced: true,
            ...definitions
        },
        Users: {
            namespaced: true,
            ...users
        },
        Pictures: {
            namespaced: true,
            ...images
        },
        Audiotrack: {
            namespaced: true,
            ...audiotracks
        },
        Exercises: {
            namespaced: true,
            ...exercises
        }
    }
});
