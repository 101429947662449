import gr from './gr.json'
import tr from './tr.json'
import en from './en.json'

export const defaultLocale = 'en';

export const languages = {
    gr,
    tr,
    en
};
