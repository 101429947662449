<template>
        <v-row justify="center">

            <v-col cols="12">
                <Search/>
            </v-col>

            <v-col cols="12">
                <Topics/>
            </v-col>
        </v-row>
</template>

<script>
    import ChangeLanguageComponent from "../../components/frontend/home/Change-language-component";
    import SpecialCharacters from "../../components/frontend/home/SpecialCharacters";
    import ComboBoxComponent from "../../components/frontend/home/ComboBox-component";
    import Topics from "../../components/frontend/home/Topics";
    import Search from "../../components/frontend/home/Search";

    export default {
        components: {
            ChangeLanguageComponent,
            SpecialCharacters,
            ComboBoxComponent,
            Topics,
            Search

        },
        computed: {
            model() {
                return this.$store.getters["Definitions/getPhraseModel"] === null || this.$store.getters["Definitions/getPhraseModel"] === undefined ? {} : this.$store.getters["Definitions/getPhraseModel"];
            }
        }
    };
</script>

<style scoped>
    * {
        color: #555555 !important;
    }
</style>
