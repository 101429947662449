/*
    Imports the Phrasebook API URL from the config.
*/

export default {
    login: function(payload) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;

        return axios.post("auth/login", payload);
    },

    checkAuth: payload => {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + payload.token;

        return axios.post("auth/me");
    },

    logout: () => {
        return axios.post("/auth/logout");
    },

    loadAllUsers: () => {
        return axios.get("/admin/loadAllUsers");
    },

    newUser: payload => axios.post("/admin/newUser", payload),

    loadUser: payload => axios.get("/admin/user/" + payload),

    updateUser: payload => axios.put("/admin/updateUser" , payload),

    sendEmail: payload => axios.post("/sendEmail", payload)
};
