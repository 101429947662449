window.axios = require('axios');

import Vue from 'vue'

import {HCNM_CONFIG} from "../config";

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = HCNM_CONFIG.API_URL;
Vue.prototype.$http = axios;

// Csrf token
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
